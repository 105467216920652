

































import { Component, Vue, Provide, Watch } from "vue-property-decorator"
import { AbstractDataProps } from "./components/Abstract/Abstract.vue"
import updateTdk, { formatTDK } from "@/utils/tdk"
import { watch } from "@vue/composition-api"
import { getMetaInfo } from "@/utils/meta"
import { appBoundle } from "@/utils/base"
import downloadDialog from "@/components/downloadDialog"
import api from "@/api/api"
import { urlScheme } from "@/utils/base"

const { openApp } = appBoundle()

@Component({
  name: "Game",
  beforeRouteUpdate(to, from, next) {
    if (to.query.type) {
      this.type = to.fullPath.includes("comments") ? "comment" : "detail"
      next()
    }
    if (to.params.game_id !== this.$route.params.game_id) {
      this.tabs = [
        { label: "详情", value: "detail" },
        { label: "专区", value: "zone" },
        { label: "评论", value: "comment" },
        { label: "论坛", value: "bbs" }
      ]
      this.videoData = null
      this.abstractData = null
      this.detailData = null
      this.fetchData(to.params.game_id)
    }

    next()
  },
  beforeRouteLeave(to, from, next) {
    this.saveScollTop()
    next()
  },
  components: {
    TopVideo: () => import("./components/TopVideo/TopVideo.vue"),
    Abstract: () => import("./components/Abstract/Abstract.vue"),
    Detail: () => import("./components/Detail/Detail.vue"),
    Comments: () => import("./components/Comments/Comments.vue"),
    OpenAppButton: () => import("@/components/openAppButton/openAppButton.vue")
  },
  tdk() {
    return this.$store.state.tdk
  }
})
export default class Game extends Vue {
  @Provide("changeTab")
  changeTab(type) {
    this.type = type
  }

  launchAppUrl = ""

  get gameEvents() {
    return this.$store.state.gameList.gameEvents
  }

  saveScollTop() {
    if (!this.$isServer) {
      const homeMain = document.querySelector(".home-main")
      if (homeMain) {
        let position = homeMain.scrollTop
        this.$store.commit("position/SAVE_GAME_DETAIL", position) //离开路由时把位置存起来
      }
    }
  }

  videoData = null
  abstractData: AbstractDataProps = null
  detailData = null
  tabs = [
    { label: "详情", value: "detail" },
    { label: "专区", value: "zone" },
    { label: "评论", value: "comment" },
    { label: "论坛", value: "bbs" }
  ]
  type = this.$route.fullPath.includes("comments") ? "comment" : "detail"
  // ssr hook
  serverPrefetch() {
    return this.fetchData()
  }

  beforeMount() {
    if (
      !this.$store.state.gameList.gameDetail ||
      !this.$store.state.gameList.gameAbstract ||
      !this.$store.state.gameList.filterTags
    ) {
      this.fetchData()
    } else {
      this.formatData()
    }
  }

  updated() {
    this.$nextTick(function() {
      let position = this.$store.state.position.gameDetail //返回页面取出来
      // safari 不支持el.scroll
      if (document.querySelector(".home-main").scroll && position) {
        document.querySelector(".home-main").scroll(0, position)
      }
    })
  }

  destroyed() {
    this.$store.commit("gameList/SET_GAME_DETAIL", { gameDetail: null })
  }

  fetchData(game_id?) {
    return Promise.all([
      this.$store.dispatch("tdk/FETCH_TDK", {
        type: "game",
        _id_or_name: game_id || this.$route.params.game_id
      }),
      this.$store.dispatch("gameList/FETCH_GAME_ABSTRACT", {
        game_id: game_id || this.$route.params.game_id
      }),
      this.$store.dispatch("gameList/FETCH_GAME_DETAIL", {
        game_id: game_id || this.$route.params.game_id
      }),
      this.$store.dispatch("gameList/FETCH_FILTER_TAGS"),
      this.$store.dispatch("gameList/FETCH_GAME_EVENTS", {
        game_id: game_id || this.$route.params.game_id
      })
    ])
      .then(() => {
        this.formatData()
        this.formatTdk()
      })
      .catch(error => {
        this.$router.push({ name: "404" })
      })
      .finally(() => {
        updateTdk.call(this)
      })
  }

  formatTdk() {
    let desc =
      this.$store.state.gameList.gameDetail.detail_tab.filter(
        v => v.type === "des"
      ).length > 0
        ? this.$store.state.gameList.gameDetail.detail_tab
            .filter(v => v.type === "des")[0]
            .des.substr(0, 50)
        : ""

    this.$store.commit(
      "tdk/SET_TDK",
      formatTDK(this.$store.state.tdk, [
        {
          replaceKey: "{游戏名称}",
          replaceVal: this.$store.state.gameList.gameAbstract.name
        },
        {
          replaceKey: "{下载按钮文案}",
          replaceVal: this.downloadStatusText
        },
        {
          replaceKey: "{游戏简介}",
          replaceVal: desc
        }
      ])
    )
    this.$root.$tstore.modules.tdk.mutations.SET_ICON({
      icon: this.$store.state.gameList.gameAbstract.icon
    })
  }

  formatData() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type as string
    }

    const gameAbstract = JSON.parse(
      JSON.stringify(this.$store.state.gameList.gameAbstract)
    )
    const gameDetail = JSON.parse(
      JSON.stringify(this.$store.state.gameList.gameDetail)
    )

    const { top_video } = gameDetail
    this.videoData = top_video

    this.abstractData = {
      category: gameAbstract.category,
      name: gameAbstract.name + (gameDetail.name_suffix || ""),
      tags: gameAbstract.new_tag_style?.filter(
        tag => !this.$store.state.gameList.filterTags.includes(tag.name)
      ),
      icon: gameAbstract.icon,
      star: gameAbstract.new_star,
      rank: gameDetail.ranking
        ? `${gameDetail.ranking.column_name}第${gameDetail.ranking.no}名`
        : "",
      download_status: gameAbstract.download_status,
      download_off_text: gameAbstract.download_off_text || "",
      _id: gameAbstract._id
    }
    if (gameAbstract.h5_link) {
      this.abstractData.h5_link = gameAbstract.h5_link
    }

    if (!gameDetail.zone_tab || gameDetail.zone_tab.style !== "link") {
      this.tabs.splice(this.tabs.map(v => v.label).indexOf("专区"), 1)
    }
    if (!gameDetail.bbs_tab) {
      this.tabs.splice(this.tabs.map(v => v.label).indexOf("论坛"), 1)
    }
    if (!gameAbstract.show_comment) {
      this.tabs.splice(this.tabs.map(v => v.label).indexOf("评论"), 1)
    }

    this.detailData = gameDetail.detail_tab
    if (window) {
      this.launchAppUrl = urlScheme.game(
        this.abstractData._id,
        "desc",
        String(this.$route.query.from || "")
      )
      this.appointmentDownload()
    }
  }

  handleDisabled(type) {
    if (
      type === "zone" &&
      this.$store.state.gameList.gameDetail?.zone_tab?.style === "link"
    ) {
      window.open(this.$store.state.gameList.gameDetail.zone_tab.link, "_blank")
    } else if (
      type === "bbs" &&
      this.$store.state.gameList.gameDetail?.bbs_tab?.link
    ) {
      this.$router
        .push(
          `/bbs/${this.$store.state.gameList.gameDetail?.bbs_tab?.link_seq}`
        )
        .catch(err => {
          console.log(err)
        })
    }
  }

  scrollAnimate() {
    setTimeout(() => {
      const duration = 500
      const beginTime = Date.now()
      const beginValue =
        (document.querySelector(".tabs") as HTMLElement).offsetTop - 50
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
      const cubic = value => Math.pow(value, 3)
      const easeInOutCubic = value =>
        value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / duration
        if (progress < 1 && document.querySelector(".home-main")) {
          document.querySelector(".home-main").scrollTop =
            beginValue * easeInOutCubic(progress)
          rAF(frameFunc)
        }
      }
      rAF(frameFunc)
    }, 200)
  }

  @Watch("type")
  onType(type) {
    if (type === "comment") {
      this.scrollAnimate()
      this.$router.replace({
        path: `${this.$route.path}/comments`
      })
    } else {
      this.$router.replace({
        path: this.$route.path.includes("/game")
          ? `/game/${this.$route.params.game_id}`
          : `/${this.$route.params.game_id}`
      })
    }
  }

  get downloadStatusText() {
    let text = "下载"
    if (this.abstractData.download_status) {
      switch (this.abstractData.download_status) {
        case "on":
          text = "下载"
          break
        case "off":
          text = this.abstractData.download_off_text || "暂无下载"
          break
        case "appointment":
          text = "预约"
          break
        case "demo":
          text = "试玩"
          break
        case "link":
          text = this.abstractData.h5_link.text || "下载"
          break
      }
    }
    return text
  }
  async appointmentDownload() {
    try {
      // GHZSCY-6800
      if (
        !window.GhzsUa.UA.Wechat &&
        this.$route.query.from === "wechat_appointment"
      ) {
        const res = await api.games.gameAppointmentJump()
        if (res?.data?.status === "on") {
          setTimeout(() => {
            openApp(this.launchAppUrl, function() {
              downloadDialog(
                {
                  rmAfterDestroy: false,
                  presetData: {
                    from: "wechat_appointment",
                    download_link:
                      "https://down-and.ghzs.com/?channel=AppointmentGame"
                  }
                },
                () => {}
              )
            })
          }, 1000)
        }
      }
    } catch (error) {}
  }
}
