import Vue from "vue"

// 格式化时间
export function DateFotmat(date, fmt = "yyyy-MM-dd") {
  const o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  }

  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    )

  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  }

  return fmt
}
/**
 * 格式化时间戳
 * @param timestamp {number} - 时间戳
 * @param fmt {string} - 返回格式
 * @returns {timestamp}
 */
export function TimestampFormat(
  timestamp: number,
  fmt = "yyyy-MM-dd hh:mm:ss"
): string {
  let _timestamp
  // 第一步：检查date值的长度，长度10位则乘以1000，换成带毫秒的格式
  if (!timestamp) return ""
  if (typeof timestamp === "number" && timestamp.toString().length === 10) {
    timestamp = timestamp * 1000
  }
  // 第二步：转为原生的Date对象
  _timestamp = new Date(timestamp)
  // 第三步：格式化
  const o = {
    "M+": _timestamp.getMonth() + 1, //月份
    "d+": _timestamp.getDate(), //日
    "h+": _timestamp.getHours(), //小时
    "m+": _timestamp.getMinutes(), //分
    "s+": _timestamp.getSeconds(), //秒
    "q+": Math.floor((_timestamp.getMonth() + 3) / 3), //季度
    S: _timestamp.getMilliseconds() //毫秒
  }

  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (_timestamp.getFullYear() + "").substr(4 - RegExp.$1.length)
    )

  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  }

  // 第四步：检查时长
  const compareTime = (Date.now() - timestamp) / 1000
  const _just = 60 // 一分钟内显示秒
  const _minute = 60 * 60 // 一小时内显示分钟
  const _hour = 3600 * 24 // 一天内显示小时
  const _day = 3600 * 24 * 7 // 七天内显示天数
  const _week = 3600 * 24 * 30 // 一个月内显示星期
  const _month = 3600 * 24 * 30 * 3 // 三个月后显示具体时间
  if (compareTime < _just) {
    return "刚刚"
  } else if (compareTime >= _just && compareTime < _minute) {
    return Math.floor(compareTime / _just) + "分钟前"
  } else if (compareTime >= _minute && compareTime < _hour) {
    return Math.floor(compareTime / _minute) + "小时前"
  } else if (compareTime >= _hour * 24 && compareTime < _day) {
    return Math.floor(compareTime / _hour) + "天前"
  } else if (compareTime >= _day && compareTime < _week) {
    return Math.floor(compareTime / _day) + "周前"
  } else if (compareTime >= _week && compareTime < _month) {
    return Math.floor(compareTime / _week) + "个月前"
  } else {
    return fmt
  }
}

/**
 * 防抖
 * @param context
 * @param fn
 * @param delay
 */
export function debounce(context, fn, delay = 300) {
  let timer = null
  const self = context || this
  function doEvent(...args) {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      fn.apply(self, args)
    }, delay)
  }
  return doEvent
}

/**
 * 立即执行模式的防抖
 * @param cb - 回调函数
 * @param delay - 延迟时间
 */
export function IIFEDebounce(cb: Function, delay: number = 500) {
  let timeout = null
  return (function() {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(cb, delay)
  })()
}

export function copyText(text, callback) {
  var textarea = document.createElement("textarea")
  textarea.style.position = "fixed" // 防止页面滚动
  textarea.style.opacity = "0"
  textarea.style.left = "-9999px" // 确保元素在视口外
  document.body.appendChild(textarea)
  textarea.value = text
  textarea.select()
  textarea.setSelectionRange(0, textarea.value.length) // 兼容移动端

  try {
    if (document.execCommand("copy")) {
      Vue.prototype.$toast(`已复制`)
      typeof callback === "function" && callback()
    } else {
      window.alert("当前系统不支持复制操作，请自行复制。")
    }
  } catch (err) {
    window.alert("复制失败，请手动复制。")
  }

  document.body.removeChild(textarea)
}

function factory() {
  let __cur_page_visible__ = true
  let timer = null

  function event() {
    __cur_page_visible__ = !document.hidden
  }

  function addEvent() {
    document.addEventListener("visibilitychange", event)
  }

  function removeEvent() {
    document.removeEventListener("visibilitychange", event)
  }

  function openApp(scheme?, failURL?, delay = 1200) {
    if (timer) {
      clearTimeout(timer)
    }

    const ifr = document.createElement("iframe")
    ifr.src = scheme ? scheme : "ghzhushou://"
    ifr.style.display = "none"
    document.body.appendChild(ifr)
    const start_time = Date.now()
    timer = setTimeout(() => {
      document.body.removeChild(ifr)
      const end_time = Date.now()
      if (__cur_page_visible__ && end_time - start_time < 1500) {
        if (typeof failURL === "function") {
          failURL && failURL()
        } else {
          window.location = failURL ? failURL : "https://www.ghzs.com"
        }
      }
    }, delay)
  }

  return {
    __cur_page_visible__,
    addEvent,
    removeEvent,
    openApp
  }
}

export function appBoundle() {
  return factory()
}

/**
 * @param dateTimeStamp 毫秒
 */
export function timeago(dateTimeStamp) {
  const minute = 1000 * 60 //把分，时，天，周，半个月，一个月用毫秒表示
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const halfamonth = day * 15
  const month = day * 30
  const now = new Date().getTime() //获取当前时间毫秒
  const nowYear = new Date().getFullYear() // 此刻年份
  const year = new Date(dateTimeStamp).getFullYear() // 时间戳年份
  const diffValue = now - dateTimeStamp //时间差

  if (diffValue < 0) {
    return
  }
  let result
  const minC = diffValue / minute //计算时间差的分，时，天，周，月
  const hourC = diffValue / hour
  const dayC = diffValue / day
  const weekC = diffValue / week
  const monthC = diffValue / month
  if (year < nowYear) {
    result = DateFotmat(new Date(dateTimeStamp), "yyyy-MM-dd")
  } else if (year >= nowYear) {
    result = DateFotmat(new Date(dateTimeStamp), "MM-dd")
  } else if (dayC > 1 && dayC <= 7) {
    result = " " + dayC.toFixed(0) + "天前"
  } else if (dayC === 1) {
    result = "昨天"
  } else if (hourC >= 1 && hourC <= 23) {
    result = " " + hourC.toFixed(0) + "小时前"
  } else if (minC >= 1 && minC <= 59) {
    result = " " + minC.toFixed(0) + "分钟前"
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚"
  } else {
    const datetime = new Date()
    datetime.setTime(dateTimeStamp)
    const Nyear = datetime.getFullYear()
    const Nmonth =
      datetime.getMonth() + 1 < 10
        ? "0" + (datetime.getMonth() + 1)
        : datetime.getMonth() + 1
    const Ndate =
      datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate()
    // var Nhour =
    //   datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours()
    // var Nminute =
    //   datetime.getMinutes() < 10
    //     ? "0" + datetime.getMinutes()
    //     : datetime.getMinutes()
    // var Nsecond =
    //   datetime.getSeconds() < 10
    //     ? "0" + datetime.getSeconds()
    //     : datetime.getSeconds()
    result = Nyear + "-" + Nmonth + "-" + Ndate
  }
  return result
}

/**
 * 检测图片是否存在
 * @param url
 */
export const imageIsExist = function(url) {
  return new Promise(resolve => {
    let img = new Image()
    img.onload = function() {
      if (img.complete === true) {
        resolve(true)
        img = null
      }
    }
    img.onerror = function() {
      resolve(false)
      img = null
    }
    img.src = url
  })
}

export function getParam(name) {
  const results = window.location.href.match(
    new RegExp("[?&]" + name + "=([^&#]*)")
  )
  if (results === null) {
    return ""
  } else {
    return decodeURI(results[1]) || ""
  }
}

/**
 * 防抖节流
 * @param {*} action 回调
 * @param {*} delay 等待的时间
 * @param {*} context this指针
 * @param {Boolean} iselapsed 是否等待上一次,也就是iselapsed为true，则为节流
 * @returns {Function}
 */
export function throttle(action, delay, context, iselapsed) {
  let timeout = null
  let lastRun = 0
  return function() {
    if (timeout) {
      if (iselapsed) {
        return
      } else {
        clearTimeout(timeout)
        timeout = null
      }
    }
    const elapsed = Date.now() - lastRun
    // eslint-disable-next-line
    const args = arguments
    if (iselapsed && elapsed >= delay) {
      runCallback()
    } else {
      timeout = setTimeout(runCallback, delay)
    }
    /**
     * 执行回调
     */
    function runCallback() {
      lastRun = Date.now()
      timeout = false
      action.apply(context, args)
    }
  }
}

export function isPC() {
  if (!process.env.isSSR) {
    const userAgent = navigator.userAgent
    const _isPC = !/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      userAgent
    )
    return _isPC
  } else {
    return false
  }
}

const dsBridge = process.env.isSSR ? {} : require("dsbridge")
// 光环内是否有该方法
export function hasGhMethod(method) {
  return dsBridge.hasNativeMethod(method) || false
}

export function inGhzs(type?: String) {
  const method = type === "ios" ? "isGhiOS" : "isGhzs"
  if (process.env.isSSR) return false
  return hasGhMethod(method) && dsBridge.call(method, "")
}
/**
 * @desc 判断是否在插件内
 */
export function inPlugin() {
  if (process.env.isSSR) return false
  return window.Java && typeof window.Java.webFullscreen === "function"
}

export function getPluginVersion() {
  if (process.env.isSSR) return false
  if (
    window.Java &&
    window.Java.readPluginEnvironment &&
    window.Java.readPluginEnvironment()
  ) {
    return JSON.parse(window.Java.readPluginEnvironment())
  }
  return false
}
// 判断插件登录功能
export function checkPluginLogin() {
  if (process.env.isSSR) return false

  const pluginVersion = getPluginVersion()
  if (pluginVersion) {
    return pluginVersion.is_login_enabled
  }
  return false
}

export function isGreaterOrEqual(version1: string, version2: string): boolean {
  const version1Parts = version1.split(".").map(Number)
  const version2Parts = version2.split(".").map(Number)

  for (let i = 0; i < version1Parts.length; i++) {
    if (version1Parts[i] > version2Parts[i]) {
      return true
    } else if (version1Parts[i] < version2Parts[i]) {
      return false
    }
  }

  return true
}

export function getUserToken() {
  if (process.env.isSSR) return false
  if (window.Java && window.Java.getUserToken) {
    return window.Java.getUserToken()
  }
  return false
}

/**
 * https://git.shanqu.cc/halo/web/web-page/-/blob/master/app-android-js/urlscheme.md
 */
export const urlScheme = {
  // "desc" 详情 | "comment" 评论 | "zone" 专区
  game: (game_id: string, to: "desc" | "comment" | "zone", from?: string) => {
    let url = `ghzhushou://game/${game_id}?to=${to}&auto_download=false`
    if (from) {
      return `${url}&from=${from}`
    }
    return url
  },
  gameComment: (game_id: string, comment_id: string) => {
    return `ghzhushou://game_rating_detail?game_id=${game_id}&comment_id=${comment_id}`
  },
  bbs: (bbs_id: string) => {
    return `ghzhushou://forum_detail?id=${bbs_id}`
  },
  artcle: (articleId: string) => {
    return `ghzhushou://article/${articleId}`
  },
  communityArticle: (articleId: string, communityId: string) => {
    return `ghzhushou://community.article?articleId=${articleId}&communityId=${communityId}`
  },
  video: (videoId: string, gameId: string, url: string) => {
    return `ghzhushou://video/${videoId}?id=${gameId}&referer=${url}`
  }
}

export function loadScript(url, callback) {
  var script = document.createElement("script")
  script.type = "text/javascript"
  script.src = url
  // if (script.readyState) { // IE
  //   script.onreadystatechange = function() {
  //     if (script.readyState === "loaded" || script.readyState === "complete") {
  //       script.onreadystatechange = null
  //       callback()
  //     }
  //   }
  // } else {
  // }
  script.onload = function() {
    callback()
  }
  document.body.appendChild(script)
}

export interface ITableData<T> {
  page?: number
  page_size?: number
  total?: number
  finished?: boolean
  data?: T[]
}

export class TableData<T> {
  // 字段
  page: number
  page_size: number
  total: number
  finished: boolean
  loading: boolean
  data: T[]

  // 构造函数
  constructor({
    page = 1,
    page_size = 30,
    total = 0,
    finished = false,
    data = []
  }: ITableData<T>) {
    this.page = page
    this.page_size = page_size
    this.total = total
    this.finished = finished
    this.data = data
  }

  // 方法
  loadTable() {}
}

export function formatSeconds(seconds: number) {
  var time = seconds || 0
  let m: any = parseInt(time / 60 + ""),
    s: any = parseInt((time % 60) + "")

  m = m < 10 ? "0" + m : m
  s = s < 10 ? "0" + s : s
  return m + ":" + s
}

export const redirectAppLinkInHTML = html => {
  return html.replace(/ghzhushou:\/\/[^("|')]*/g, match => {
    return `javascript:void(0); redirect('${match}')`
  })
}

/**
 * 判断一个字符串是否是以 http 或 https 开头的URL
 * @param url - 要验证的字符串
 * @returns 如果是以 http 或 https 开头的URL返回 true，否则返回 false
 */
export function isHttpURL(url: string): boolean {
  return url.startsWith("http://") || url.startsWith("https://")
}

// https://jira.shanqu.cc/browse/GHZSCY-5428
// 内容带有{{url}}格式，{{url}}在客户端、内容站网页端 需用蓝色展示且点击后需在APP内跳转该链接
// 内容带有###内容###格式，###内容###在客户端、内容站网页端需用蓝色展示且点击后需复制且toast提示复制成功；
export const formatURLInHTML = html => {
  let safeHtml = html
  if (!Vue.prototype.$isServer) {
    safeHtml = window.DOMPurify.sanitize(html)
  }
  let h = safeHtml.replace(/{{(.*?)}}/g, match => {
    const text = match.replace(/{{|}}/g, "")
    if (isHttpURL(text)) {
      return `<a style="color: #007AFF;" onclick="javascript:void(0);" href="${text}">${text}</a>`
    } else {
      return `<span style="color: #007AFF;" onclick="javascript:void(0); copyText('${text}')">${text}</span>`
    }
  })
  return h.replace(/###(.*?)###/g, match => {
    return `<span style="color: #007AFF;" onclick="javascript:void(0); copyText('${match.replace(
      /###/g,
      ""
    )}')">${match.replace(/###/g, "")}</span>`
  })
}
