







































































































import { DateFotmat, urlScheme } from "@/utils/base"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import TagsDLG from "@/components/tagsDLG"

export interface AbstractDataProps {
  icon: string
  name: string
  tags: any[]
  star: string
  rank: string
  category: string
  download_status: string
  download_off_text?: string
  h5_link?: {
    link: string
    type: string
    text: string
    close_button: string
  }
  _id: string
}

@Component({
  name: "Abstract",
  components: {}
})
export default class Abstract extends Vue {
  urlScheme = urlScheme
  @Prop()
  dataProps: AbstractDataProps

  @Prop()
  gameEvents

  calendarIcon = {
    active: require("@/assets/images/icon_rili2.png"),
    inactive: require("@/assets/images/icon_rili.png")
  }
  arrowIcon = {
    active: require("@/assets/images/icon_right_arrow2.png"),
    inactive: require("@/assets/images/icon_right_arrow.png")
  }
  wechatTipsShow = false

  get isActive() {
    let flag = false
    if (this.gameEvents && this.gameEvents.length > 0) {
      const start = this.gameEvents[0].time - 7 * 24 * 60 * 60
      const end = this.gameEvents[0].time + 3 * 24 * 60 * 60
      const now = new Date().getTime() / 1000
      if (now >= start && now <= end) {
        flag = true
      }
    }
    return flag
  }

  get formatDate() {
    let date = ""
    if (this.gameEvents && this.gameEvents.length > 0) {
      date = DateFotmat(new Date(this.gameEvents[0].time * 1000), "yyyy-MM-dd")
    }
    return date
  }

  get text() {
    let text = ""
    if (this.gameEvents && this.gameEvents.length > 0) {
      text = this.gameEvents[0].content
    }
    return text
  }
  $refs: { events: HTMLElement; content: HTMLElement }
  mounted() {
    this.isWechat()
    this.$nextTick(() => {
      if (this.$refs.events) {
        this.$refs.events.style.width =
          this.$refs.content.offsetWidth + 50 + "px"
      }
    })
  }

  get downloadStatusText() {
    let text = "下载"
    if (this.dataProps.download_status) {
      switch (this.dataProps.download_status) {
        case "on":
          text = "下载"
          break
        case "off":
          text = this.dataProps.download_off_text || "暂无下载"
          break
        case "appointment":
          text = "预约"
          break
        case "demo":
          text = "试玩"
          break
        case "link":
          text = this.dataProps.h5_link.text || "下载"
          break
      }
    }
    return text
  }

  handleMoreTags() {
    TagsDLG(
      {
        rmAfterDestroy: true,
        presetData: {
          gameName: this.dataProps.name,
          tags: this.dataProps.tags
        }
      },
      ({ tag }) => {
        this.$router.push(`/tag/${tag}`)
      }
    )
  }
  handleDownload(dataProps) {
    if (this.isWechat()) {
      return
    }
    this.$downloadDialog({
      game_name: dataProps.name,
      game_icon: dataProps.icon
    })
  }
  isWechat() {
    const check = window.GhzsUa.UA.Wechat || window.GhzsUa.UA.QQ
    if (check) {
      this.wechatTipsShow = true
    }
    return check
  }
  closeWechatMask() {
    this.wechatTipsShow = false
  }
}
