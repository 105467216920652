import { render, staticRenderFns } from "./floatDownload.vue?vue&type=template&id=3a4fe7ee&scoped=true"
import script from "./floatDownload.vue?vue&type=script&lang=ts"
export * from "./floatDownload.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  var style0 = require("./floatDownload.vue?vue&type=style&index=0&id=3a4fe7ee&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3a4fe7ee",
  "b44d1232"
  
)

export default component.exports